.sul-box-table {
  background-color: var(--color-bg);
  box-shadow: -5px -5px 20px var(--color-white), 5px 5px 20px var(--color-shadow);
  color: rgba(0, 0, 0, 0.90);
  cursor: pointer;
  border-radius: 12px;

  &:hover {
    background-color: var(--color-bg);
    box-shadow: -2px -2px 5px var(--color-white), 2px 2px 5px var(--color-shadow);
  }

  &:active{
    box-shadow: inset 1px 1px 2px var(--color-shadow), inset -1px -1px 2px var(--color-white);
  }

  td {
    background-color: rgba(0, 0, 0, 0.0);
    border:none;
    &:hover {
      background-color: rgba(0, 0, 0, 0.0);
    }
  }

}

.sul-table{
  border-collapse: separate;
  border-spacing:0 10px;
  border-radius: 6px;
}

.sul-tr{
  background-color: rgba(0, 0, 0, 0.0);
  &:hover {
    background-color: rgba(0, 0, 0, 0.0);
  }
}
