/********************************************* COMMON PROPERTIES *********************************************/

.sul-btn,
.sul-text-field,
.sul-checkbox-type-1,
.sul-checkbox-type-2,
.sul-radio,
.sul-select {
  -webkit-appearance: none;
  appearance: none;
  border: 0 !important;
  outline: 0 !important;
  transition: var(--transition);
  color: var(--main-text-color);
}

/******************************************** TEXT FIELDS & BUTTONS ********************************************/

.sul-btn,
.sul-text-field {
  font-size: 16px;
  border-radius: var(--text-field-radius);
  background: var(--color-bg);


  &::placeholder {
    color: var(--main-text-color);
    opacity: 0.5;
  }
}

/************************************************* TEXT FIELDS *************************************************/

.sul-text-field {
  box-shadow: inset 2px 2px 5px var(--color-shadow), inset -5px -5px 10px var(--color-white);

  &:focus {
    box-shadow: inset 1px 1px 2px var(--color-shadow), inset -1px -1px 2px var(--color-white);
  }
}

/************************************************** BUTTONS **************************************************/

.sul-btn {
  box-shadow: -5px -5px 20px var(--color-white), 5px 5px 20px var(--color-shadow);
  text-align: center;

  &:hover {
    box-shadow: -2px -2px 5px var(--color-white), 2px 2px 5px var(--color-shadow);
  }

  &:active {
    box-shadow: inset 1px 1px 2px var(--color-shadow), inset -1px -1px 2px var(--color-white);
  }

  &.btn-primary {
    background: var(--primary-color);
    color: #eee;
  }

  .btn-block {
    width: 100%;
  }
}

/******************************************** BUTTONS, CHECKBOX & RADIO ******************************************/

.sul-btn,
.sul-radio,
.sul-checkbox-type-1,
.sul-checkbox-type-2 {
  cursor: pointer;
}

/*********************************************** CHECKBOX & RADIO ***********************************************/

.sul-radio,
.sul-checkbox-type-1 {
  box-shadow: 2px 2px 4px var(--color-shadow), -2px -2px 4px var(--color-white);
  background: transparent;
  justify-content: center;
  align-items: center;

  &::before {
    transition: var(--transition);
    font-family: "Font Awesome 5 Free";
    font-size: calc(var(--ruler) * 1.5);
    font-weight: bolder;
    text-shadow: -3px -3px 5px var(--color-shadow), 3px 3px 5px var(--color-white);
    color: var(--color-bg);
  }

  &:checked {
    box-shadow: -3px -3px 5px var(--color-shadow), 3px 3px 5px var(--color-white);
    &::before {
      text-shadow: 3px 3px 5px var(--color-shadow), -3px -3px 5px var(--color-white);
      color: var(--check-radio-color);
    }
  }

  &:disabled {
    box-shadow: -3px -3px 5px var(--color-shadow), 3px 3px 5px var(--color-white);

    &::before {
      text-shadow: 3px 3px 5px var(--color-shadow), -3px -3px 5px var(--color-white);
      color: var(--color-bg);
    }
  }
}

/***************************************************** CHECKBOX *************************************************/

.sul-checkbox-type-1 {
  border-radius: calc(var(--ruler) * 1);

  &::before,
  &:checked::before,
  &:disabled {
    content: "\f00c";
  }
}

/***************************************************** RADIO *****************************************************/

.sul-radio {
  border-radius: 50%;

  &::before,
  &:checked::before,
  &:disabled {
    content: "\f111";
  }
}

/***************************************************** SELECT *****************************************************/

.sul-select-wrapper {
  position: relative;

  &::after {
    transition: var(--transition);
    font-family: "Font Awesome 5 Free";
    content: "\f107";
    font-size: calc(var(--ruler) * 3);
    font-weight: bolder;
    text-shadow: 1px 1px 2px var(--color-shadow), -1px -1px 2px var(--color-white);
    color: var(--check-radio-color);
    position: absolute;
    right: 20px;
    top: 5%;
    z-index: 1;
  }

  .sul-select {
    position: relative;
    z-index: 2;
    font-size: 16px;
    border-radius: var(--text-field-radius);
    padding: var(--ruler);
    background: var(--color-bg);
    width: 100%;
    box-shadow: inset 0 0 0 transparent, inset 0 0 0 transparent, 2px 2px 3px var(--color-shadow),
      -2px -2px 3px var(--color-white);
    transition: var(--transition);

    &:focus {
      box-shadow: inset 2px 2px 3px var(--color-shadow), inset -2px -2px 3px var(--color-white);
    }

    option {
      background: var(--color-bg);
    }
  }
}

/***************************************************** CHECKBOX TYPE 2 *****************************************/

.sul-checkbox-type-2 {
  border-radius: calc(var(--ruler) * 1);
  box-shadow: inset 2px 2px 4px var(--color-shadow), inset -2px -2px 4px var(--color-white);
  max-width: 50px;
  min-width: 50px;
  max-height: 25px;
  min-height: 25px;
  background: var(--color-bg);
  margin-right: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &::before {
    transition: var(--transition);
    font-family: "Font Awesome 5 Free";
    content: "\f111";
    font-size: calc(var(--ruler) * 2.5);
    font-weight: bolder;
    text-shadow: 2px 2px 3px var(--color-shadow), -2px -2px 3px var(--color-white);
    color: var(--color-bg);
    position: relative;
    left: -15px;
  }

  &:checked {
    box-shadow: inset 2px 2px 4px var(--primary-color), inset -2px -2px 4px var(--primary-color);
    background: var(--primary-color);
    max-width: 50px;
    min-width: 50px;
    max-height: 25px;
    min-height: 25px;
    &::before {
      left: 15px;
    }
  }
}

/***************************************************** RANGE *****************************************************/

.sul-range {
  -webkit-appearance: none;
  width: 100%;
  height: calc(var(--ruler) * 2.5);
  background: var(--color-bg);
  outline: none;
  border-radius: calc(var(--ruler) * 1);
  box-shadow: inset 2px 2px 3px var(--color-shadow), inset -2px -2px 3px var(--color-white);

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
  }

  &::-webkit-slider-thumb {
    min-width: calc(var(--ruler) * 3.5);
    min-height: calc(var(--ruler) * 3.5);
    max-width: calc(var(--ruler) * 3.5);
    max-height: calc(var(--ruler) * 3.5);
    border: none;
    border-radius: 50%;
    box-shadow: 2px 2px 3px var(--color-shadow), -2px -2px 3px var(--color-white);
    background: var(--color-bg);
    cursor: pointer;
  }

  &::-moz-range-thumb {
    min-width: calc(var(--ruler) * 3.5);
    min-height: calc(var(--ruler) * 3.5);
    max-width: calc(var(--ruler) * 3.5);
    max-height: calc(var(--ruler) * 3.5);
    border: none;
    border-radius: 50%;
    box-shadow: 2px 2px 3px var(--color-shadow), -2px -2px 3px var(--color-white);
    background: var(--color-bg);
    cursor: pointer;
  }
}
.sul-dropdown {
  &:hover {
    box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff;
    background-color: transparent;
  }
}

.sul-dropdown-menu {
  box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff
}
