.table-names {
  font-size: x-large;
  font-weight: lighter;
}

label[required="required"]{ position: relative;}
label[required="required"]::before{
  content: '*';
  color: #f00;
}

.flash-position {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1300;
}
