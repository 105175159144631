@import url("https://fonts.googleapis.com/css?family=Rubik|Poppins:300&display=swap");

:root {
  --ruler: 13px;
  --transition: all 0.25s ease-in-out;
  --main-font: "Rubik", sans-serif;
  --main-font: "Poppins", "Rubik", sans-serif;
}

// Theses modes will be applied to the body of the html document

// Light Mode

.light-mode {
  --primary-color: #{darken(#00a8ff, 5)};
  --color-bg: #ebecf0;
  --color-white: #fff;
  --color-shadow: #{rgba($color: #babecc, $alpha: 0.9)};
  --main-text-color: #333;
  --text-field-radius: calc(var(--ruler) * 1);
  --check-radio-color: var(--primary-color);
}

// Dark Mode

.dark-mode {
  --primary-color: #{darken(#00a8ff, 10)};
  --color-bg: #333333;
  --color-white: #3b3b3b;
  --color-shadow: #{rgba($color: #2b2b2b, $alpha: 1)};
  --main-text-color: #aaa;
  --text-field-radius: calc(var(--ruler) * 1);
  --check-radio-color: var(--primary-color);
}
