@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css";
@import "variables";
@import "form";
@import "box";
@import "table";

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: var(--main-font) !important;
  background-color: var(--color-bg) !important;
  color: var(--main-text-color) !important;

  .sul-text-field {
    font-family: var(--main-font) !important;
  }
}

h1,
h2,
h3,
span,
p,
a {
  font-weight: 300 !important;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  //padding: 0;
  //margin: 0;
}

header {
  background-color: var(--color-bg) !important;
}

.sul-link {
  color: var(--primary-color) !important;
  text-decoration: none;
  position: relative !important;

  &:visited {
    color: var(--primary-color) !important;
    text-decoration: none;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--primary-color) !important;
    transition: 0.5s;
  }

  &:hover::before {
    width: 100%;
  }
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}
