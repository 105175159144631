// Include any default variable overrides here (though functions won't be available)

$secondary:                   #ebecf0;
$blue:                        #3f6bb3;
$yellow:                      #fec700;

$btn-box-shadow:              -5px -5px 20px var(--color-white), 5px 5px 20px var(--color-shadow);
$btn-focus-box-shadow:        -2px -2px 5px var(--color-white), 2px 2px 5px var(--color-shadow);
$btn-active-box-shadow:       inset 1px 1px 2px var(--color-shadow), inset -1px -1px 2px var(--color-white);
$btn-border-radius:           13px;
$btn-link-hover-color:        $secondary;

$modal-content-bg:           var(--color-bg);

$input-box-shadow:            -5px -5px 20px var(--color-white), 5px 5px 20px var(--color-shadow);
$input-bg:                    $secondary;
$input-focus-box-shadow:      inset 1px 1px 2px var(--color-shadow), inset -1px -1px 2px var(--color-white);
$input-border-radius:         calc(var(--ruler) * 1);
$form-check-input-focus-box-shadow: inset 1px 1px 2px var(--color-shadow), inset -1px -1px 2px var(--color-white);

$card-bg:                     var(--color-bg);
$card-box-shadow:             -5px -5px 20px var(--color-white), 5px 5px 20px var(--color-shadow);
$card-border-radius:          calc(var(--ruler) * 1);

$box-shadow:                  -5px -5px 20px var(--color-white), 5px 5px 20px var(--color-shadow);

$form-select-box-shadow:      -5px -5px 20px var(--color-white), 5px 5px 20px var(--color-shadow);

$dropdown-box-shadow:         inset 1px 1px 2px var(--color-shadow), inset -1px -1px 2px var(--color-white);;

$dropdown-bg:                 $secondary;
$dropdown-link-hover-bg:      $secondary;
$dropdown-link-active-bg:     $secondary;
$dropdown-padding-y:          0;


@import "/node_modules/bootstrap/scss/bootstrap.scss";
